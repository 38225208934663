import React from 'react';
import styled, { css } from 'styled-components';
import { forDesktop, underDesktop } from '@westwing/ui-kit/breakPoints';
import { AspectRatio } from '@westwing/ui-kit/AspectRatio';
import { LinkedBanners } from '../../../redux/seo/types';
import HybridImage from '../../Generic/HybridImage';
import { categoryBannerRatios } from 'Client/constants/aspectRatios';
import { MediaAsset, MediaInfusionItem } from 'Client/redux/mediaInfusion/types';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';

const MIN_IMG_HEIGHT = 180;

const mobileImagePlaceHolderStyles = css`
    margin: 0 10px;
    min-height: ${MIN_IMG_HEIGHT}px;
    background: ${({ theme }) => theme.colors.charcoal2};
    ${forDesktop`
        min-height: auto;
        margin: 0;
    `}
`;

const StyledImg = styled(HybridImage)`
    ${({ mobileSrc }) =>
        !mobileSrc &&
        css`
            display: none;

            ${forDesktop`
                display: flex;
            `}
        `}

    ${AspectRatio}
`;

const StyledContentfulImg = styled(HybridImage)`
    ${({ mobileSrc }) =>
        !mobileSrc &&
        css`
            display: none;

            ${forDesktop`
                display: flex;
            `}
        `}
`;

const desktopImagePlaceHolderStyles = css`
    ${forDesktop`
        min-height: ${MIN_IMG_HEIGHT}px;
        margin: 0;
        background: ${({ theme }) => theme.colors.charcoal2};
    `}
`;

const desktopNoImageStyles = css`
    ${forDesktop`
        display: none;
    `}
`;

const StyledBanners = styled.div<{ hasDesktopBanners: boolean; hasMobileBanners: boolean }>`
    ${({ hasDesktopBanners, hasMobileBanners }) => css`
        ${hasMobileBanners ? mobileImagePlaceHolderStyles : ''}
        ${hasDesktopBanners ? desktopImagePlaceHolderStyles : desktopNoImageStyles}
    `}
`;

const StyledBanner = styled.div<{ isDesktop?: boolean }>`
    background: ${({ theme }) => theme.colors.white};
    ${({ isDesktop }) =>
        isDesktop &&
        css`
            ${forDesktop`
                padding: 0 0 10px;
            `}
        `}
`;

export const StyledBannersImage = styled('img')`
    width: 100%;
`;

const ImageLinks = styled.a`
    background: ${({ theme }) => theme.colors.white};
    padding: 0;
`;

interface BannersProps {
    banners?: LinkedBanners[];
    bannerTitle: string;
    mediaInfusion?: MediaInfusionItem;
}

const displayBanner = (banner: LinkedBanners, bannerTitle: string, Component = StyledImg) => {
    const { bannerImage, desktopBannerImage, bannerLink } = banner;
    if (!bannerImage && !desktopBannerImage) {
        return null;
    }

    const imgCmp = (
        <Component
            mobileSrc={bannerImage}
            desktopSrc={desktopBannerImage}
            alt={bannerTitle}
            ratio={categoryBannerRatios}
        />
    );
    if (!bannerLink) {
        return imgCmp;
    }

    return (
        <ImageLinks href={bannerLink} key={bannerImage}>
            {imgCmp}
        </ImageLinks>
    );
};

const StyledVideo = styled.video<{ $isDesktop?: boolean }>`
    width: 100%;
    display: none;
    ${({ $isDesktop }) =>
        $isDesktop
            ? forDesktop`
                display:block;
            `
            : underDesktop`
            display:block;
        `}
`;

const displayVideo = (asset: MediaAsset, isDesktop?: boolean, deeplinkUrl = '') => {
    if (!asset?.file?.url) {
        return null;
    }

    const videoCmp = (
        <StyledVideo $isDesktop={isDesktop} loop autoPlay muted playsInline>
            <source src={asset.file.url} type={asset.file.contentType} />
        </StyledVideo>
    );

    if (!deeplinkUrl) {
        return videoCmp;
    }

    return (
        <ImageLinks href={deeplinkUrl} key={asset.file.url}>
            {videoCmp}
        </ImageLinks>
    );
};

const renderAsset = (asset: MediaAsset, isDesktop?: boolean, deeplinkUrl = '') => {
    if (asset.file.contentType.includes('video')) {
        return displayVideo(asset, isDesktop, deeplinkUrl);
    }
    if (asset.file.contentType.includes('image')) {
        const banner = {
            desktopBannerImage: isDesktop ? asset.file?.url : '',
            bannerImage: !isDesktop ? asset.file?.url : '',
            bannerLink: deeplinkUrl,
        };
        return displayBanner(banner, asset.title, StyledContentfulImg);
    }
    return null;
};

export const MediaInfusion: React.FC<{ infusion: MediaInfusionItem }> = ({ infusion }) => (
    <div data-testid="mediaInfusion">
        {infusion.mediaDesktop && renderAsset(infusion.mediaDesktop, true, infusion.deeplinkUrl)}
        {infusion.mediaMobile && renderAsset(infusion.mediaMobile, false, infusion.deeplinkUrl)}
    </div>
);

const Banners: React.FC<BannersProps> = ({ banners = [], bannerTitle = '', mediaInfusion = null }) => {
    const { p100PlpVideo } = useFeatureToggle();
    const showVideo = p100PlpVideo && mediaInfusion;

    if (banners.length === 0 && !mediaInfusion) {
        return null;
    }

    const hasDesktopBanners = banners.filter(b => b.desktopBannerImage.length > 0).length > 0;
    const hasMobileBanners = banners.filter(b => b.bannerImage.length > 0).length > 0;
    return showVideo ? (
        <MediaInfusion infusion={mediaInfusion} />
    ) : (
        <StyledBanners data-testid="banners" hasDesktopBanners={hasDesktopBanners} hasMobileBanners={hasMobileBanners}>
            {banners.map(banner => (
                <StyledBanner
                    isDesktop={banner.desktopBannerImage.length > 0}
                    key={`${banner.desktopBannerImage}-${banner.bannerImage}`}
                >
                    {displayBanner(banner, bannerTitle)}
                </StyledBanner>
            ))}
        </StyledBanners>
    );
};

export default Banners;
