/* eslint-disable no-restricted-syntax */
import React, { FC, ReactElement } from 'react';
import { ListingPageUrlState } from '../../../redux/products/types';
import { SeoData } from '../../../redux/seo/types';
import { buildCanonicalObject, buildPaginationHeadlinkObject, computeSeoIndexableAndFollow } from '../services/seo';
import { hrefLang } from '../services/seo/hrefLang';
import { SEO_CONSTANTS } from '../services/seo/constant';
import GenericSeoHead, { LinkProps } from '../../Generic/SeoHead';
import SeoBreadcrumbScript from '../../Generic/SeoHead/SeoBreadcrumbScript';
import { convertListingPageStateToUrl } from '../services/listingPageUrlService';
import { CategoryEntity } from '../../../redux/category/types';
import useRouter from 'AppShell/hooks/useRouter';
import SeoProductListScript from './SeoProductListScript';
import { Config, PageType } from 'AppShell/appshell_types';
import { hasPageNoIndexParams, shouldRenderHreflangs } from 'Common/helpers/urlHelpers';
import {
    SeoPLPDuschvorhaengeStructuredData,
    SeoPLPTeekannenStructuredData,
} from 'Client/components/ProductDetailPage/Seo/SeoPLPStructuredData';
import { Dictionary } from 'Utils/types/utility';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';

interface Props {
    pageTitle: string;
    pageHeaderTitle: string;
    urlState: ListingPageUrlState;
    seoData: SeoData;
    config: Config;
    totalCount: number;
    entities: Dictionary<CategoryEntity>;
    urlKeys: Dictionary<string>;
    openGraphImage?: string;
}

export const getSeoHeadProps = (
    pageTitle: string,
    urlState: ListingPageUrlState,
    seoData: SeoData,
    totalCount: number,
    config: Config,
    search: string,
    p100EnablePtHreflang?: boolean
    // eslint-disable-next-line max-params
) => {
    const {
        links: { url: host },
        listingPageSize,
    } = config;

    // seoData.metaRobots, as a first step checking what value is set BOB (only for the first page)
    const { page = 1 } = urlState;
    const shouldUseMetaRobots = page === 1 && seoData.metaRobots;
    const robotsIndexAbleContent = hasPageNoIndexParams(search)
        ? SEO_CONSTANTS.noIndexable
        : shouldUseMetaRobots || computeSeoIndexableAndFollow(urlState);
    const shouldIndex = robotsIndexAbleContent === SEO_CONSTANTS.indexable;
    let linkForHelmet: LinkProps[] = [];
    if (shouldIndex) {
        const seoUrlState = { totalCount, urlState, listingPageSize };
        const hrefLangs = shouldRenderHreflangs(search)
            ? hrefLang(seoData, config, urlState, p100EnablePtHreflang)
            : [];
        const canonical = buildCanonicalObject(urlState, seoData, config.links.url);
        const prevLink = buildPaginationHeadlinkObject(SEO_CONSTANTS.prevRelKeyLink, seoUrlState, host);
        const nextLink = buildPaginationHeadlinkObject(SEO_CONSTANTS.nextRelKeyLink, seoUrlState, host);
        linkForHelmet = [...hrefLangs, canonical, prevLink, nextLink];
    }

    return {
        robotsIndexAbleContent,
        links: linkForHelmet,
        pageTitle: pageTitle || seoData.seoTitle,
        pageDescription: seoData.seoDescription,
    };
};

const ListingPageSeoHead: FC<Props> = ({
    pageTitle,
    pageHeaderTitle,
    urlState,
    seoData,
    config,
    totalCount,
    entities,
    urlKeys,
    openGraphImage,
}): ReactElement => {
    const { location } = useRouter();
    const { p100EnablePtHreflang } = useFeatureToggle();
    const seoHeadProps = getSeoHeadProps(
        pageTitle,
        urlState,
        seoData,
        totalCount,
        config,
        location.search,
        p100EnablePtHreflang
    );
    const pageUrl = convertListingPageStateToUrl(urlState, config.links.url);
    const listStructuredData = [
        { listingPageType: 'category', listingPageKey: 'teekannen', component: <SeoPLPTeekannenStructuredData /> },
        {
            listingPageType: 'category',
            listingPageKey: 'duschvorhaenge',
            component: <SeoPLPDuschvorhaengeStructuredData />,
        },
    ];
    const structuredComponent = listStructuredData.find(
        item =>
            urlState.listingPageType === item.listingPageType &&
            urlState.listingPageKey === item.listingPageKey &&
            urlState.appliedFilters.length === 0
    )?.component;

    const SeoScript =
        seoData.isPartOfABTest || urlState.listingPageType === PageType.BRANDS
            ? SeoProductListScript
            : SeoBreadcrumbScript;

    return (
        <>
            {structuredComponent}
            <GenericSeoHead
                {...seoHeadProps}
                pageUrl={pageUrl}
                config={config}
                type={SEO_CONSTANTS.ogTypeContentValue}
                openGraphImage={openGraphImage}
            />
            <SeoScript
                host={config.links.url}
                pageHeaderTitle={pageHeaderTitle}
                entities={entities}
                urlKeys={urlKeys}
                urlState={urlState}
            />
        </>
    );
};

export default ListingPageSeoHead;
